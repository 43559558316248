<template>
	<ej-page>
		<ej-mobile-header>
			<ej-toolbar>
				<ej-buttons>
					<ej-back-button></ej-back-button>
				</ej-buttons>

				<ej-title>{{ $route.meta.title }}</ej-title>

				<ej-buttons style="margin-left: auto">
					<ej-button
						@click="sortingToggle"
						:class="{ active: sortingOpened, 'mr-1': true }"
						v-if="_.get($store.state, `${module}.sorting.enabled`)"
					>
						<font-awesome-icon :icon="['fas', 'sort']"></font-awesome-icon>
					</ej-button>

					<ej-button
						@click="filteringToggle"
						:class="{ active: filteringOpened }"
						v-if="_.get($store.state, `${module}.filtering.enabled`)"
					>
						<font-awesome-icon :icon="['fas', 'filter']"></font-awesome-icon>
					</ej-button>
				</ej-buttons>
			</ej-toolbar>
		</ej-mobile-header>

		<ej-content>
			<ej-breadcrumb>
				<li class="breadcrumb-item">
					{{ $route.meta.title }}
				</li>
			</ej-breadcrumb>

			<div class="header">
				<div class="container-fluid">
					<div class="row">
						<div class="col-lg-7">
							<div :class="{ row: true, 'mobile-hidden': filteringMobileHidden }">
								<h4 class="col-12">Filtros</h4>

								<div class="form-group col-lg-4 col-12">
									<label>Campo:</label>
									<multiselect
										v-model="filteringField"
										:options="$store.state[module].filtering.fields"
										:searchable="true"
										:close-on-select="true"
										:show-labels="false"
										placeholder=""
										track-by="value"
										label="name"
									></multiselect>
								</div>

								<div class="form-group col-lg-4 col-12">
									<label>Critério:</label>
									<multiselect
										v-model="filteringCriteria"
										:options="$store.state[module].filtering.criterias"
										:searchable="true"
										:close-on-select="true"
										:show-labels="false"
										placeholder=""
										track-by="value"
										label="name"
									></multiselect>
								</div>

								<div class="form-group col-lg-4 col-12">
									<label>Pesquisar por:</label>
									<input type="text" class="form-control" v-model="filteringKeyword">
								</div>
							</div>
						</div>

						<div class="col-lg-5">
							<div :class="{ row: true, 'mobile-hidden': sortingMobileHidden }">
								<h4 class="col-12">Ordenação</h4>

								<div class="form-group col-lg-6 col-12">
									<label>Campo:</label>
									<multiselect
										v-model="sortingField"
										:options="$store.state[module].sorting.fields"
										:searchable="true"
										:close-on-select="true"
										:show-labels="false"
										placeholder=""
										track-by="value"
										label="name"
									></multiselect>
								</div>

								<div class="form-group col-lg-6 col-12">
									<label>Ordem:</label>
									<multiselect
										v-model="sortingDirection"
										:options="$store.state[module].sorting.directions"
										:searchable="true"
										:close-on-select="true"
										:show-labels="false"
										placeholder=""
										track-by="value"
										label="name"
									></multiselect>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>


			<main class="content">
				<div class="d-flex justify-content-center" v-if="$store.state.automessages.fetching">
					<div class="default-spinner">
						<div class="default-spinner-inner">
							<div></div>
							<div></div>
						</div>
					</div>
				</div>
				<div v-else>
					<div class="container-fluid">
						<div class="row">
							<div class="col">
                                <div class="d-flex" style="justify-content: center">
                                    <a
                                        href="https://business.facebook.com/wa/manage/message-templates/?business_id=617783880375950&waba_id=101699989625275"
                                        class="btn btn-primary"
                                        style="color: #0069d9; display: flex; align-items: center; justify-content: center; width: 210px"
                                    >Templates WhatsApp&nbsp;<font-awesome-icon :icon="['fas', 'external-link-alt']"></font-awesome-icon></a>
                                </div>

								<table class="desktop-list-content">
									<thead>
										<tr>
											<th class="sortable" @click="setOrder('automessages.id')">
												Id <ej-order-icon :module="module" :field="'automessages.id'"></ej-order-icon>
											</th>
											<th class="sortable" @click="setOrder('automessages.name')">
												Nome <ej-order-icon :module="module" :field="'automessages.name'"></ej-order-icon>
											</th>
                                            <th class="sortable" @click="setOrder('automessages.line')">
                                                Linha <ej-order-icon :module="module" :field="'automessages.line'"></ej-order-icon>
                                            </th>
                                            <th class="sortable" @click="setOrder('automessages.interval')">
                                                Intervalo <ej-order-icon :module="module" :field="'automessages.interval'"></ej-order-icon>
                                            </th>
                                            <th class="sortable" @click="setOrder('automessages.unit')">
                                                Un. <ej-order-icon :module="module" :field="'automessages.unit'"></ej-order-icon>
                                            </th>
                                            <th class="sortable" @click="setOrder('automessages.trigger')">
                                                Gatilho <ej-order-icon :module="module" :field="'automessages.trigger'"></ej-order-icon>
                                            </th>
                                            <th class="sortable" @click="setOrder('automessages.active')">
                                                Ativo <ej-order-icon :module="module" :field="'automessages.active'"></ej-order-icon>
                                            </th>
                                            <th class="sortable" @click="setOrder('automessages.active')">
                                                WP Template <ej-order-icon :module="module" :field="'automessages.wp_template_name'"></ej-order-icon>
                                            </th>
											<th class="sortable" @click="setOrder('automessages.created_at')">
												Atualizado em <ej-order-icon :module="module" :field="'automessages.updated_at'"></ej-order-icon>
											</th>
                                            <th class="sortable" @click="setOrder('automessages.created_at')">
                                                Criado em <ej-order-icon :module="module" :field="'automessages.created_at'"></ej-order-icon>
                                            </th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										<tr :key="index" v-for="(automessage, index) in $store.state.automessages.automessages">
											<td>{{ automessage.id }}</td>
											<td>{{ automessage.name }}</td>
											<td>{{ automessage.line }}</td>
											<td>{{ automessage.interval }}</td>
											<td>{{ translateUnit(automessage.unit) }}</td>
											<td>{{ translateTrigger(automessage.trigger) }}</td>
                                            <td>{{ translateActive(automessage.active) }}</td>
                                            <td>{{ automessage.wp_template_name }}</td>
											<td>{{ translateDate(automessage.updated_at) }}</td>
											<td>{{ translateDate(automessage.created_at) }}</td>
											<td class="actions">
                                                <router-link title="Envios" class="btn btn-primary mr-1" :to="{name: 'automessages.mailings', params: {id: automessage.id}}">
                                                    <font-awesome-icon :icon="['fas', 'share']"></font-awesome-icon>
                                                </router-link>
                                                <router-link title="Editar" class="btn btn-primary mr-1" :to="{name: 'automessages.edit', params: {id: automessage.id}}">
													<font-awesome-icon :icon="['far', 'edit']"></font-awesome-icon>
												</router-link>
												<button title="Deletar" class="btn btn-primary" @click="deleteAutomessage(automessage.id, index)">
													<font-awesome-icon :icon="['far', 'trash-alt']"></font-awesome-icon>
												</button>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>

						<div class="mobile-list-content">
							<div class="row">
								<div class="col-12 col-md-6" :key="index" v-for="(automessage, index) in $store.state.automessages.automessages">
									<div class="card">
										<div class="card-body">
											<h5 class="card-title"><strong>{{ automessage.id }}</strong> - {{ automessage.name }}</h5>
											<p class="card-text"><strong>Criado em:</strong> {{ translateDate(automessage.created_at) }}</p>

											<div class="actions">
												<router-link class="btn btn-primary" :to="{name: 'automessages.edit', params: {id: automessage.id}}">
													<font-awesome-icon :icon="['far', 'edit']"></font-awesome-icon>
												</router-link>

                                                <button class="btn btn-primary" @click="deleteAutomessage(automessage.id, index)">
                                                    <font-awesome-icon :icon="['far', 'trash-alt']"></font-awesome-icon>
                                                </button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="container-fluid">
						<div class="row">
							<div class="col d-flex justify-content-center">
								<nav class="pagination-nav" aria-label="Page navigation example">
									<ul class="pagination">
										<li class="page-item" v-if="$store.state.automessages.pagination.current_page > 1">
											<button class="page-link" @click="setPage($store.state.automessages.pagination.current_page-1)">Anterior</button>
										</li>
										<li :class="{ 'page-item':true, active: page === $store.state.automessages.pagination.current_page}" :key="index" v-for="(page, index) in $store.state.automessages.pagination.last_page">
											<button class="page-link" @click="setPage(page)">{{ page }}</button>
										</li>
										<li class="page-item" v-if="$store.state.automessages.pagination.current_page < $store.state.automessages.pagination.last_page">
											<button class="page-link" @click="setPage($store.state.automessages.pagination.current_page+1)">Próxima</button>
										</li>
									</ul>
								</nav>
							</div>
						</div>
					</div>
				</div>
			</main>

			<ej-floating-button @click="$router.push({name: 'automessages.create'})">
				<font-awesome-icon :icon="['fas', 'plus']"></font-awesome-icon>
			</ej-floating-button>
		</ej-content>
	</ej-page>
</template>

<script>
	//Components
	import Multiselect from 'vue-multiselect';

	//Mixins
	import SortingMixin from "@/mixins/SortingMixin";
	import FilteringMixin from "@/mixins/FilteringMixin";
	import PaginationMixin from "@/mixins/PaginationMixin";
	import DateMixin from "@/mixins/DateMixin";
	import FetchingMixin from "@/mixins/FetchingMixin";
	import CurrencyMixin from "@/mixins/CurrencyMixin";

	//Aux
	import {customWatch, exibeErro} from "@/helpers";
	import Swal from "sweetalert2";

	export default {
		data() {
			return {
				module: 'automessages'
			}
		},

		mixins: [
			FilteringMixin,
			SortingMixin,
			PaginationMixin,
			DateMixin,
			FetchingMixin,
			CurrencyMixin
		],

		watch: {
			"$store.state.automessages.sorting.selected": customWatch,
			"$store.state.automessages.filtering.selected": customWatch,
			"$store.state.automessages.filtering.keyword": customWatch,
			"$store.state.automessages.pagination.current_page": customWatch,
		},

		methods: {
			deleteAutomessage(id, index) {
				const vm = this;

				Swal.fire({
					title: 'Confirmação',
					text: 'Você tem certeza que deseja excluir essa mensagem programada?',
					icon: 'warning',
					confirmButtonText: 'Sim, excluir',
					confirmButtonColor: '#d33',
					showCancelButton: true,
				}).then(function(result) {
					if (result.isConfirmed)
						vm.$store.dispatch(`automessages/delete`, id)
							.then(function() {
								Swal.fire(
									'Sucesso!',
									'Mensagem programada deletada com sucesso!',
									'success'
								).then(() => {
									vm.$delete(vm.$store.state.automessages.automessages, index);
								});
							})
							.catch(function (error) {
								// handle error
								exibeErro(error);
							})
							.then(function () {
								// always executed
							});
				});
			}
		},

		mounted() {
			//Fetch inicial
			this.requestData();
		},

		components: {
			Multiselect,
		},
	}
</script>

<style scoped>

</style>
