<template>
	<ej-page>
		<ej-mobile-header>
			<ej-toolbar>
				<ej-buttons>
					<ej-back-button></ej-back-button>
				</ej-buttons>

				<ej-title>{{ $route.meta.title }}</ej-title>

				<ej-buttons style="margin-left: auto">
					<ej-button
						@click="sortingToggle"
						:class="{ active: sortingOpened, 'mr-1': true }"
						v-if="_.get($store.state, `${module}.sorting.enabled`)"
					>
						<font-awesome-icon :icon="['fas', 'sort']"></font-awesome-icon>
					</ej-button>

					<ej-button
						@click="filteringToggle"
						:class="{ active: filteringOpened }"
						v-if="_.get($store.state, `${module}.filtering.enabled`)"
					>
						<font-awesome-icon :icon="['fas', 'filter']"></font-awesome-icon>
					</ej-button>
				</ej-buttons>
			</ej-toolbar>
		</ej-mobile-header>

		<ej-content>
			<ej-breadcrumb>
				<li class="breadcrumb-item">
					{{ $route.meta.title }}
				</li>
			</ej-breadcrumb>

			<div class="header">
				<div class="container-fluid">
					<div class="row">
						<div class="col-lg-7">
							<div :class="{ row: true, 'mobile-hidden': filteringMobileHidden }">
								<h4 class="col-12">Filtros</h4>

								<div class="form-group col-lg-4 col-12">
									<label>Campo:</label>
									<multiselect
										v-model="filteringField"
										:options="$store.state[module].filtering.fields"
										:searchable="true"
										:close-on-select="true"
										:show-labels="false"
										placeholder=""
										track-by="value"
										label="name"
									></multiselect>
								</div>

								<div class="form-group col-lg-4 col-12">
									<label>Critério:</label>
									<multiselect
										v-model="filteringCriteria"
										:options="$store.state[module].filtering.criterias"
										:searchable="true"
										:close-on-select="true"
										:show-labels="false"
										placeholder=""
										track-by="value"
										label="name"
									></multiselect>
								</div>

								<div class="form-group col-lg-4 col-12">
									<label>Pesquisar por:</label>
									<input type="text" class="form-control" v-model="filteringKeyword">
								</div>
							</div>
						</div>

						<div class="col-lg-5">
							<div :class="{ row: true, 'mobile-hidden': sortingMobileHidden }">
								<h4 class="col-12">Ordenação</h4>

								<div class="form-group col-lg-6 col-12">
									<label>Campo:</label>
									<multiselect
										v-model="sortingField"
										:options="$store.state[module].sorting.fields"
										:searchable="true"
										:close-on-select="true"
										:show-labels="false"
										placeholder=""
										track-by="value"
										label="name"
									></multiselect>
								</div>

								<div class="form-group col-lg-6 col-12">
									<label>Ordem:</label>
									<multiselect
										v-model="sortingDirection"
										:options="$store.state[module].sorting.directions"
										:searchable="true"
										:close-on-select="true"
										:show-labels="false"
										placeholder=""
										track-by="value"
										label="name"
									></multiselect>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>


			<main class="content">
				<div class="d-flex justify-content-center" v-if="$store.state.automessage_mailings.fetching">
					<div class="default-spinner">
						<div class="default-spinner-inner">
							<div></div>
							<div></div>
						</div>
					</div>
				</div>
				<div v-else>
					<div class="container-fluid">
						<div class="row">
							<div class="col">
								<table class="desktop-list-content">
									<thead>
										<tr>
											<th class="sortable" @click="setOrder('automessage_mailings.id')">
												Id <ej-order-icon :module="module" :field="'automessage_mailings.id'"></ej-order-icon>
											</th>
											<th class="sortable" @click="setOrder('automessage_mailings.order_id')">
												Pedido <ej-order-icon :module="module" :field="'automessage_mailings.order_id'"></ej-order-icon>
											</th>
                                            <th class="sortable" @click="setOrder('automessage_mailings.sent')">
                                                Enviado <ej-order-icon :module="module" :field="'automessage_mailings.sent'"></ej-order-icon>
                                            </th>
                                            <th class="sortable" @click="setOrder('automessage_mailings.wp_msg_status')">
                                                Status WP <ej-order-icon :module="module" :field="'automessage_mailings.wp_msg_status'"></ej-order-icon>
                                            </th>
                                            <th class="sortable" @click="setOrder('automessage_mailings.created_at')">
                                                Criado em <ej-order-icon :module="module" :field="'automessage_mailings.created_at'"></ej-order-icon>
                                            </th>
                                            <th class="sortable" @click="setOrder('automessage_mailings.updated_at')">
                                                Atualizado em <ej-order-icon :module="module" :field="'automessage_mailings.updated_at'"></ej-order-icon>
                                            </th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										<tr :key="index" v-for="(mailing, index) in $store.state.automessage_mailings.automessage_mailings">
											<td>{{ mailing.id }}</td>
											<td>
                                                <router-link
                                                    :to="{name:'orders.show',params: {id: mailing.order_id}}"
                                                    href="#"
                                                >{{ mailing.order_id }}</router-link>
                                            </td>
											<td>{{ mailing.sent }}</td>
											<td>{{ mailing.wp_msg_status }}</td>
											<td>{{ translateDate(mailing.created_at) }}</td>
											<td>{{ translateDate(mailing.updated_at) }}</td>
											<td class="actions">

											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>

					<div class="container-fluid">
						<div class="row">
							<div class="col d-flex justify-content-center">
								<nav class="pagination-nav" aria-label="Page navigation example">
									<ul class="pagination">
										<li class="page-item" v-if="$store.state.automessage_mailings.pagination.current_page > 1">
											<button class="page-link" @click="setPage($store.state.automessage_mailings.pagination.current_page-1)">Anterior</button>
										</li>
										<li :class="{ 'page-item':true, active: page === $store.state.automessage_mailings.pagination.current_page}" :key="index" v-for="(page, index) in $store.state.automessage_mailings.pagination.last_page">
											<button class="page-link" @click="setPage(page)">{{ page }}</button>
										</li>
										<li class="page-item" v-if="$store.state.automessage_mailings.pagination.current_page < $store.state.automessage_mailings.pagination.last_page">
											<button class="page-link" @click="setPage($store.state.automessage_mailings.pagination.current_page+1)">Próxima</button>
										</li>
									</ul>
								</nav>
							</div>
						</div>
					</div>
				</div>
			</main>
		</ej-content>
	</ej-page>
</template>

<script>
	//Components
	import Multiselect from 'vue-multiselect';

	//Mixins
	import SortingMixin from "@/mixins/SortingMixin";
	import FilteringMixin from "@/mixins/FilteringMixin";
	import PaginationMixin from "@/mixins/PaginationMixin";
	import DateMixin from "@/mixins/DateMixin";
	import FetchingMixin from "@/mixins/FetchingMixin";
	import CurrencyMixin from "@/mixins/CurrencyMixin";

	//Aux
	import {customWatch, exibeErro} from "@/helpers";
	import Swal from "sweetalert2";

	export default {
		data() {
			return {
				module: 'automessage_mailings'
			}
		},

		mixins: [
			FilteringMixin,
			SortingMixin,
			PaginationMixin,
			DateMixin,
			FetchingMixin,
			CurrencyMixin
		],

		watch: {
			"$store.state.automessage_mailings.sorting.selected": customWatch,
			"$store.state.automessage_mailings.filtering.selected": customWatch,
			"$store.state.automessage_mailings.filtering.keyword": customWatch,
			"$store.state.automessage_mailings.pagination.current_page": customWatch,
		},

		methods: {
			deleteAutomessage(id, index) {
				const vm = this;

				Swal.fire({
					title: 'Confirmação',
					text: 'Você tem certeza que deseja excluir essa mensagem programada?',
					icon: 'warning',
					confirmButtonText: 'Sim, excluir',
					confirmButtonColor: '#d33',
					showCancelButton: true,
				}).then(function(result) {
					if (result.isConfirmed)
						vm.$store.dispatch(`automessage_mailings/delete`, id)
							.then(function() {
								Swal.fire(
									'Sucesso!',
									'Mensagem programada deletada com sucesso!',
									'success'
								).then(() => {
									vm.$delete(vm.$store.state.automessage_mailings.automessage_mailings, index);
								});
							})
							.catch(function (error) {
								// handle error
								exibeErro(error);
							})
							.then(function () {
								// always executed
							});
				});
			}
		},

		async mounted() {
            await this.$store.dispatch(`${this.module}/setAutomessage`, this.$route.params.id);

			//Fetch inicial
			this.requestData();
		},

		components: {
			Multiselect,
		},
	}
</script>

<style scoped>

</style>
